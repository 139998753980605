import React from "react"
import PropTypes from "prop-types"
import { Tiles } from "@rebass/layout"
import LonelyPlanetCard from "./LonelyPlanetCard"
import styled from "styled-components"

const MobileContainer = styled.div`
    display: none;
    background: #f8f8f8;
    
   @media (max-width: 480px) {
      display: flex;
    }
`;

const MobileCardContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    min-height: 100vh;
    
    & > div { 
      margin: auto auto 
    }
`;

const LiveMobile = ({ items }) => (
  <MobileContainer>
    {items.map(item => (
      <MobileCardContainer className="slide" key={item.link}>
        <Tiles
          style={{
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <a href={item.link}>
            <LonelyPlanetCard img={item.img} text={item.text} />
          </a>
        </Tiles>
      </MobileCardContainer>
    ))}
  </MobileContainer>
)

LiveMobile.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      img: PropTypes.string,
      text: PropTypes.string,
      link: PropTypes.string,
    })
  ),
}

export default LiveMobile
