import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Link } from "rebass"

const Card = styled.div`
  height: 330px;
  max-width: 350px;
  position: relative;
  box-shadow: 0 12px 34px rgba(0, 0, 0, 0.12);
  background: #f8f8f8;
  overflow: hidden;

  &:hover .cover-photo {
    transform: scale(1.03);
  }
`

const CardImage = styled.div`
  overflow: hidden;
  position: relative;
  margin: 0;
  padding: 0;
`

const CoverPhoto = styled.div`
  height: 175px;
  width: 350px;
  background-size: 100%;
  background-repeat: none;
  background-image: url(${props => props.img});
  background-position: cover;
  background-color: rgb(228, 228, 228);
  position: relative;
  transition: transform 800ms ease-in-out 0s;
`

const CardText = styled.div`
  padding: 10px 22px 34px;
`

const Tag = styled.span`
  font-family: fakt-web, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  text-decoration: none;
`

const Heading = styled.span`
  font-family: "IBM Plex Sans", "Benton Sans", "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  text-transform: none;
  line-height: 1.4;
  margin: 0px;
  font-size: 1.1rem;
  color: rgb(44, 54, 67);
  display: -webkit-box;
  display: -moz-box;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 600ms ease-in-out 0s;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  box-orient: vertical;
  line-clamp: 4;
  font-weight: 300;
`

const BulletDescription = styled.div`
  color: rgb(182, 195, 202);
  font-size: 11px;
  line-height: 1.45455;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  margin-bottom: 9px;
`

const LonelyPlanetCard = ({ img, text, ...props }) => {
  return (
    <Card {...props}>
      <CardImage className="card-image">
        <CoverPhoto className="cover-photo" img={img} />
      </CardImage>

      <CardText>
        <BulletDescription>
          <Tag>TWITTER &nbsp;•&nbsp;</Tag>
          <Tag>@GEEKCHESTER_COM</Tag>
        </BulletDescription>

        <TextWithUrls className="heading">{text}</TextWithUrls>
      </CardText>
    </Card>
  )
}

export default LonelyPlanetCard

LonelyPlanetCard.propTypes = {
  img: PropTypes.string,
  text: PropTypes.string,
}

const urlRegular = /((?:http|https):\/\/\S+\.[a-z]{2,}?.*?(?: |,|$|\.))/
const hashTagRegular = /(#.*.)/
const nameRegular = /@.*./

const TextWithUrls = ({ children, ...props }) => {
  const textWithUrls = React.Children.map(children, child => {
    if (typeof child === "string") {
      return child.split(" ").map(text => {
        if (hashTagRegular.test(text))
          return (
            <Link href={`https://twitter.com/hashtag/${text.substring(1)}`}>
              {`${text} `} 
            </Link>
          )
        if (nameRegular.test(text))
          return (
            <Link href={`https://twitter.com/${text.substring(1)}`}>
              {`${text} `} 
            </Link>
          )
        if (urlRegular.test(text)) return " "
        return `${text} `
      })
    }
    return child
  })

  return <Heading {...props}>{textWithUrls}</Heading>
}

TextWithUrls.propTypes = {
  children: PropTypes.node,
}
