import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import LonelyPlanetCard from "../components/LonelyPlanetCard"
import DefaultLiveImg from "../images/twitter-live.png"
import LiveMobile from "../components/Live.mobile"

const Section = styled.div`
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    background: linear-gradient(-7deg, #f8f8f8 42%, #efefef 42%);
    padding-top: 20px;
    padding-bottom: 20px;
`

const TwitsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    
    a {
      margin: 5px;
      transform: scale(0.95);
    }
    
    @media (max-width: 480px) {
      display: none;
    }
`

const Live = () => {
  const { allTwitterStatusesUserTimelineGeekchester: data } = useStaticQuery(
    graphql`
      query {
        allTwitterStatusesUserTimelineGeekchester(
          sort: { fields: created_at, order: DESC }
        ) {
          edges {
            node {
              entities {
                media {
                  media_url_https
                }
              }
              id_str
              full_text
            }
          }
        }
      }
    `,
  )

  const twitsData = data.edges.map(
    ({
       node: {
         // eslint-disable-next-line camelcase
         full_text,
         // eslint-disable-next-line camelcase
         id_str,
         entities: { media },
       },
     }) => ({
      img: (media && media[0].media_url_https) || DefaultLiveImg,
      text: full_text,
      link: `https://www.twitter.com/Geekchester_com/status/${id_str}`,
    }),
  )

  return (
    <Section className="section" id="live-anchor">
      <LiveMobile items={twitsData} />

      <TwitsContainer>
        {
          twitsData
            .map(({ img, link, text }) => {
              return (
                <a
                  key={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={link}
                >
                  <LonelyPlanetCard img={img} text={text}/>
                </a>
              )
            })
            .slice(0, 6)}

      </TwitsContainer>
    </Section>
  )
}

export default Live